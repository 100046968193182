@import url("https://fonts.googleapis.com/css2?family=Paytone+One&display=swap");

:root {
  --ff-header: "Paytone One", sans-serif;
  /*--ff-regular: */

  --fs-regular: 1rem;
  --fs-secondary-header: 2rem;
  --fs-header: 3.8rem;

  --fc-regular: #161616;

  --bckg-color: #f7f4eb;

  --breakpoint-xs: 0;
  --breakspoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1400px;
}

body {
    margin: 0;
    padding: 0;
}


h1 {
  font-family: var(--ff-header);
  font-size: var(--fs-header);
  color: var(--fc-regular);
  line-height: 4.5rem;
  padding: 0;
}

h2 {
  font-family: var(--ff-header);
  font-size: var(--fs-secondary-header);
  color: var(--fc-regular);
}

p {
  color: var(--fc-regular);
}

button {
  font-family: var(--ff-header);
  color: var(--bckg-color);
}

.landing-page {
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 50vh;
}

.landing-page-box {
  align-self: center;
  align-content: center;
  object-fit: contain;
  display: flex;
  width: 90%;
  height: 100%;
}

.landing-page-text {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
  width: 50%;
  height: 80%;
  justify-content: center;
  padding: 6rem;
}

.landing-page-text div {
  padding: 2% 0;
}

.statistics-box {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 1rem 10%;
  width: 80%;
}

.statistics-box-element {
  display: flex;
  width: 25%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.statistics-box-element p {
  text-align: center;
  width: 80%;
}

@media only screen and (max-width: 992px) {
  .statistics-box-element {
    width: 50%;
  }

  .landing-page-box {
    text-align: center;
    align-content: center;
    justify-content: center;
    background-image: none;
    margin: 0;
    width: 100%;
    height: 100%;
  }

  .illustration-mobile {
    display: block;
    width: 100%;
  }

  .illustration-mobile img {
    max-width: 100%;
  }

  .landing-page-text {
    display: flex;
    flex-direction: column;
    margin: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    padding: 1rem;
  }

}

@media only screen and (max-width: 576px) {
  .statistics-box-element {
    width: 100%;
  }
}
